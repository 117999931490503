header {
  margin-bottom: 3rem;

  .align {
    display: flex;
    align-items: row;
    white-space: nowrap;
  }
  img {
    height: 40px;
  }
  .text {
    padding: 1rem 5.8rem;
    opacity: 80%;
    margin-left: 1.5rem;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    a {
      display: flex;
      color: #fff;
    }
    h6 {
      display: inline;
      font-size: 0.58em;
      float: right;
    }

    /* ---- RESPONSIVIDADE ---- */

    @media (min-width: 1800px) {
      padding: 1.45rem 8.3rem;
    }

    @media (max-width: 830px) {
      font-size: 0.8rem;
    }

    @media (max-width: 479px) {
      font-size: 0.6rem;
    }
  }

  .arrow {
    color: #fff;
    border: 10px;
    border-radius: 11px;
    background-color: rgb(73, 73, 73);
    font-size: 21px;
    margin-right: 0.5rem;
  }

  .arrow:hover {
    background-color: #fff;
    color: rgb(73, 73, 73);
  }

  .divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0),
      hsla(190, 100%, 82%, 0.712),
      rgba(255, 255, 255, 0)
    );

    /* ---- RESPONSIVIDADE ---- */

    @media (min-width: 1800px) {
      height: calc(1px * var(--huge-multiplier));
    }
  }
}
