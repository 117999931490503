section {
  display: flex;
  flex-direction: column;

  .chart-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1270px;
  }
}