.modalDetalhes-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .modalDetalhes-content {
    width: 60vw;
    height: 50vh;
    padding: 1%;
    background: #1f3e55;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .close-div {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      color: white;
      cursor: pointer;

      &:hover {
        transition: 0.5s;
        opacity: 0.5;
      }
    }

    .modalDetalhes-titulo {
      width: 100%;
      display: flex;
      height: 40px;
      padding: 1%;
    }

    .modalDetalhes-texto {
      width: 100%;
      display: flex;
      height: 100%;
      padding: 1%;
      overflow: auto;
      ul {
        margin-left: 20px;
        font-size: 0.875rem;
        li {
          margin: 5px 0px;
        }
      }
    }
  }
}