.swiper-item {
  width: 100%;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 18rem;
    height: 10rem;

    padding-left: 0.9rem;
    padding-right: 0.13rem;

    backdrop-filter: blur(10px);
    background: var(--cards);

    border-radius: 20px;
    box-shadow: 12px 12px 10px 0px hsla(205, 85%, 5%, 0.4);

    /* ---- RESPONSIVIDADE---- */

    @media (max-width: 1920px) {
      width: 18.1rem;
      height: 9.5rem;
    }

    @media (min-width: 1800px) {
      min-width: 24.5rem;
      height: 12.5rem;
    }

    @media (max-width: 1416px) {
      width: 16rem;
      height: 8rem;
    }

    @media (max-width: 1122px) {
      width: 15rem;
      height: 8rem;
    }

    @media (max-width: 991px) {
      width: 13.2rem;
      height: 7.5rem;

      padding-left: 1.26rem;
      padding-right: 0.53rem;
    }

    @media (max-width: 926px) and (min-width: 830px) {
      width: 13.5rem;
      height: 6.5rem;
    }

    @media (max-width: 830px) {
      width: 13.9rem;
    }

    @media (max-width: 405px) {
      width: 16rem;
      height: 9.5rem;

      padding-left: 0.86rem;
      padding-right: 0.13rem;
    }

    .info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-top: 1rem;

      .infos {
        // display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 5vw;

        p {
          display: flex;
          align-items: center;
          white-space: nowrap; // Removendo quebra de linha

          /* ---- RESPONSIVIDADE---- */

          @media (max-width: 1920px) {
            font-size: 1rem;
          }

          @media (max-width: 1416px) {
            font-size: 0.8rem;
          }

          @media (max-width: 1122px) {
            font-size: 0.75rem;
          }

          @media (max-width: 991px) {
            font-size: 0.7rem;
          }

          @media (max-width: 740px) {
            font-size: 1rem;
          }

          @media (max-width: 405px) {
            font-size: 0.8rem;
            margin-right: 0.5rem;
          }

          i {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;

            /* ---- RESPONSIVIDADE---- */

            @media (max-width: 1416px) {
              font-size: 1rem;
            }

            @media (max-width: 1122px) {
              margin-right: 0.3rem;
              font-size: 0.9rem;
            }

            @media (max-width: 991px) {
              margin-right: 0.2rem;
              font-size: 0.85rem;
            }

            @media (max-width: 740px) {
              font-size: 1.45rem;
            }

            @media (max-width: 405px) {
              font-size: 1.2rem;
            }
          }
        }

        .status {
          margin-bottom: 0.875rem;
          margin-top: 0.7rem;

          /* ---- RESPONSIVIDADE---- */

          @media (max-width: 926px) and (min-width: 830px) {
            margin-bottom: 0.475rem;
            margin-top: 0.3rem;
          }

          p {
            font-size: 0.875rem;
            font-weight: bold;

            /* ---- RESPONSIVIDADE ---- */

            @media (min-width: 1800px) {
              font-size: calc(0.875rem * var(--huge-multiplier));
            }

            @media (max-width: 1416px) {
              font-size: 0.7rem;
            }

            @media (max-width: 991px) {
              font-size: 0.65rem;
            }

            @media (max-width: 740px) {
              font-size: 1rem;
            }

            @media (max-width: 405px) {
              font-size: 0.9rem;
            }
          }

          p.waiting {
            color: var(--yellow);
          }

          p.success {
            color: var(--green);
          }

          p.error {
            color: var(--red);
          }
        }
      }

      .link {
        text-decoration: none;
        color: var(--white);
      }

      .circle {
        width: 4.4rem;
        height: 4.3rem;
        border-radius: 50px;
        margin-top: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-10%, 20%);

        /* ---- RESPONSIVIDADE ---- */

        @media (min-width: 1800px) {
          width: calc(5.4rem * var(--huge-multiplier));
          height: calc(5.3rem * var(--huge-multiplier));

          border-radius: 50%;
        }

        @media (max-width: 1122px) {
          width: 4.1rem;
          height: 4rem;
        }

        @media (max-width: 991px) {
          width: 3.4rem;
          height: 3.3rem;
        }

        @media (max-width: 926px) and (min-width: 830px) {
          width: 3rem;
          height: 2.9rem;
        }

        @media (max-width: 740px) {
          width: 5.8rem;
          height: 5.7rem;
        }

        @media (max-width: 405px) {
          width: 4rem;
          height: 3.9rem;
        }

        .num {
          font-size: 1.375rem;
          font-weight: bold;
          opacity: 50%;

          /* ---- RESPONSIVIDADE ---- */

          @media (min-width: 1800px) {
            font-size: calc(1.875rem * var(--huge-multiplier));

            border-radius: 50%;
          }

          @media (max-width: 1122px) {
            font-size: 1.5rem;
          }

          @media (max-width: 991px) {
            font-size: 1.3rem;
          }

          @media (max-width: 926px) and (min-width: 830px) {
            font-size: 1.2rem;
          }

          @media (max-width: 740px) and (min-width: 405px) {
            font-size: 2rem;
          }
        }
      }

      .success-circle {
        border: 5px solid var(--light-green);
        cursor: pointer;

        /* ---- RESPONSIVIDADE ---- */

        @media (min-width: 1800px) {
          border: calc(5px * var(--huge-multiplier)) solid var(--light-green);
        }

        @media (max-width: 1122px) {
          border: 4px solid var(--light-green);
        }

        @media (max-width: 991px) {
          border: 3.5px solid var(--light-green);
        }

        @media (max-width: 740px) {
          border: 6px solid var(--light-green);
        }
      }

      .waiting-circle {
        border: 5px solid var(--light-yellow);
        cursor: pointer;

        /* ---- RESPONSIVIDADE ---- */

        @media (min-width: 1800px) {
          border: calc(5px * var(--huge-multiplier)) solid var(--light-yellow);
        }

        @media (max-width: 1122px) {
          border: 4px solid var(--light-yellow);
        }

        @media (max-width: 991px) {
          border: 3.5px solid var(--light-yellow);
        }

        @media (max-width: 740px) {
          border: 6px solid var(--light-yellow);
        }
      }

      .error-circle {
        border: 5px solid var(--light-red);

        cursor: pointer;

        /* ---- RESPONSIVIDADE ---- */

        @media (min-width: 1800px) {
          border: calc(5px * var(--huge-multiplier)) solid var(--light-red);
        }

        @media (max-width: 1122px) {
          border: 4px solid var(--light-red);
        }

        @media (max-width: 991px) {
          border: 3.5px solid var(--light-red);
        }

        @media (max-width: 740px) {
          border: 6px solid var(--light-red);
        }
      }
    }

    .update-at {
      font-size: 0.625rem;
      opacity: 50%;
      font-size: 8px;
      min-width: 10rem;

      width: 50%;

      /* ---- RESPONSIVIDADE ---- */
    }

    /* ---- Hover Cards ---- */
    .num {
      font-size: 1.875rem;
      font-weight: bold;
      color: white;
    }

    .circle:hover.error-circle {
      border: 6px solid;
      color: #ff3e3e;
    }

    .circle:hover.waiting-circle {
      border: 6px solid;
      color: #ece90b;
    }
    .circle:hover.success-circle {
      border: 6px solid;
      color: #32cd32;
    }
  }
}
