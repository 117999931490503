.bank-name {
  margin-left: 2%;
}

.button-refresh {
  margin-left: 2%;
  margin-bottom: 4%;
}

.chart-content {
  display: flex;
  flex-direction: column !important;
  margin-right: 10%;
  margin-left: 2%;

  /* ---- RESPONSIVIDADE ---- */

  h4 {
    margin-right: 0.5rem;
    margin-left: 2rem;

    display: flex;
    align-items: center;
  }

  .loading-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.2rem solid rgba(0, 0, 0, 0.2);
    border-top-color: #ffffff;
    animation: spin 0.6s linear infinite;
    margin-left: 0.5rem;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 100%) {
    margin-right: -52px;
  }

  @media (max-width: 100%) {
    margin-right: -22px;
  }

  @media (max-width: 100%) {
    width: 850px;
  }

  @media (max-width: 100%) {
    width: 700px;
  }

  @media (max-width: 100%) {
    width: 500px;
  }

  @media (max-width: 100%) {
    width: 380px;
  }

  @media (max-width: 100%) {
    width: 220px;
  }

  @media (max-width: 850px) {
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 380px;
  }

  @media (max-width: 444px) {
    width: 220px;
  }

  .bg {
    background: hsla(0, 0%, 100%, 0.12);
    border-radius: 20px;
    margin: 1rem;
    padding: 1.56rem;
    width: 350px;
    height: 300.91px;

    @media (max-width: 444px) {
      width: 220px;
      padding: 1rem;
      border-radius: 10px;
    }
    span {
      // color: var(--green);
      // font-size: 22px;

      // margin-right: 0.5rem;
      // margin-left: 2rem;
      // margin-top: -28px;
      // display: flex;
      // align-items: center;

      /* ---- RESPONSIVIDADE ---- */
      // @media (min-width: 1800px) {
      //   font-size: calc(22px * var(--huge-multiplier));
      // }

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }
  }

  .chart-line {
    @media (max-width: 600px) {
      padding: 1.2rem;
    }

    @media (max-width: 444px) {
      padding: 0.8rem;
    }

    canvas {
      background: hsla(0, 0%, 100%, 0.877);
      border-radius: 15px;
      padding: 1rem;
    }

    .button-calendar {
      overflow: initial;
      margin-top: -27px;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 1rem;

      @media (max-width: 444px) {
        font-size: 0.8rem;
      }

      span {
        // color: var(--green);
        // font-size: 22px;

        // margin-right: 0.5rem;
        // margin-left: 2rem;
        // margin-top: -28px;
        // display: flex;
        // align-items: center;

        /* ---- RESPONSIVIDADE ---- */
        @media (min-width: 1800px) {
          font-size: calc(22px * var(--huge-multiplier));
        }

        @media (max-width: 600px) {
          margin-left: 0;
        }
      }
    }
  }

  .details {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    width: 110%;

    .p-chart-one {
      display: flex;
      align-items: center;
      height: auto;
      gap: 1rem;

      .button-calendar {
        overflow: initial;
        margin-top: 0px;
      }
      .title-span {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 0px;

        svg {
          width: 20px;
          height: 20px;
          color: var(--green);
        }
      }
      .title-p {
        margin: 0;
        color: white;
        font-size: 1rem;
      }
    }

    .p-chart-two {
      display: flex;
      align-items: center;
      height: auto;
      gap: 1rem;

      padding-bottom: 2rem;
      .button-calendar {
        overflow: initial;
        margin-top: 0px;
      }
      .title-span {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 0px;

        svg {
          width: 20px;
          height: 20px;
          color: var(--green);
        }
      }
      .title-p {
        margin: 0;
        color: white;
        font-size: 1rem;
      }
    }

    @media (max-width: 970px) {
      grid-auto-flow: row;
      grid-auto-rows: 1fr;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 444px) {
        font-size: 0.8rem;
      }
    }

    .records {
      display: flex;
      flex-direction: column;

      @media (max-width: 444px) {
        justify-content: space-between;
      }

      .text {
        font-size: 1rem;

        @media (max-width: 600px) {
          max-width: 200px;
        }

        p {
          @media (max-width: 444px) {
            font-size: 0.8rem;
          }
        }
      }

      i {
        color: var(--green);
        font-size: 1rem;

        display: flex;
        align-items: center;

        margin-top: 0.2rem;

        svg {
          width: 20px;
          height: 20px;
        }
        p {
          margin-left: 0.5rem;
          font-size: 1rem;
        }
        /* ---- RESPONSIVIDADE ---- */
        @media (min-width: 1800px) {
          font-size: calc(1.15rem * var(--huge-multiplier));
        }

        @media (max-width: 444px) {
          font-size: 0.9rem;
        }

        span {
          color: var(--white);
          font-size: 1rem;
          margin-top: 0.05rem;
          margin-left: 0.5rem;

          /* ---- RESPONSIVIDADE ---- */
          @media (min-width: 1800px) {
            font-size: calc(1rem * var(--huge-multiplier));
          }

          @media (max-width: 444px) {
            font-size: 0.8rem;
          }
        }
      }

      table {
        margin-top: 3rem;
        font-size: 0.9rem;
        color: var(--green);
        font-weight: bold;

        @media (max-width: 444px) {
          max-width: 250px;
        }

        /* ---- RESPONSIVIDADE ---- */
        @media (min-width: 1800px) {
          font-size: calc(0.9rem * var(--huge-multiplier));
        }

        td {
          line-height: 1.5rem;

          /* ---- RESPONSIVIDADE ---- */
          @media (min-width: 1800px) {
            line-height: calc(1.5rem * var(--huge-multiplier));
          }

          & + td {
            color: var(--white);
          }
        }

        .num {
          text-align: right;
        }

        tr {
          @media (max-width: 444px) {
            font-size: 0.8rem;
          }
        }
      }
    }

    .pie {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex: 1;
      //margin-left: -2rem;

      @media (max-width: 600px) {
        max-width: 280px;
      }

      @media (max-width: 444px) {
        max-width: 260px;
        margin-top: 1rem;

        flex-direction: column-reverse;
      }

      .infos-pie {
        @media (max-width: 444px) {
          margin-left: -1.6rem;
        }

        h6 {
          margin-left: 1rem;

          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 0.8rem;
          margin-bottom: 0.05rem;

          @media (max-width: 444px) {
            font-size: 0.8rem;
          }

          span {
            background-color: var(--green);
            color: rgba(255, 255, 255, 0);
            // border: 1px solid hsl(163, 82%, 24%);

            font-size: 0rem;
            width: 2rem;
            height: 0.8rem;

            margin-right: 0.3rem;

            /* ---- RESPONSIVIDADE ---- */
            @media (min-width: 1800px) {
              width: calc(2rem * var(--huge-multiplier));
            }

            @media (max-width: 444px) {
              font-size: 0.8rem;

              width: 1rem;
              height: 0.8rem;
            }
          }

          .hl7 {
            background-color: var(--red);
            border: 1px solid hsl(0, 0%, 60%);

            /* ---- RESPONSIVIDADE ---- */
            @media (min-width: 1800px) {
              border: calc(1px * var(--huge-multiplier)) solid hsl(0, 0%, 60%);
            }
          }

          .xml {
            background-color: #e3a254;
            border: 1px solid hsl(0, 0%, 60%);

            /* ---- RESPONSIVIDADE ---- */
            @media (min-width: 1800px) {
              border: calc(1px * var(--huge-multiplier)) solid hsl(0, 0%, 60%);
            }
          }
        }
      }
    }

    .integrations {
      justify-content: flex-start;

      table {
        td.icon {
          display: flex;

          i {
            margin-right: 0.5rem;

            @media (max-width: 444px) {
              font-size: 0.8rem;
            }
          }
        }

        tr {
          @media (max-width: 444px) {
            font-size: 0.8rem;
          }
        }
      }
    }

    //Estilização responsavel pelo calendario e afins
    .calendar-popover {
      background-color: transparent !important;
      border-radius: 5px;
      box-shadow: none;
      margin-top: -18px;
      position: absolute;
      width: 310px;
      height: 280px;
      border-radius: 15px;
      overflow: inherit;
      margin-left: -6px;
    }

    .calendar-popover-pie {
      background-color: #f0f0f0;
      border-radius: 5px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      margin-top: -28px;
      position: absolute;
      width: 310px;
      height: 280px;
      border-radius: 15px;
      overflow: inherit;
      margin-left: -15px;
    }

    .calendar-popover-pie .div-calendar {
      position: relative;
    }

    .react-calendar {
      width: 310px;
      height: 280px;
      border-radius: 15px;
    }

    .react-calendar__year-view__months__month {
      height: 40px;
    }

    .react-calendar__month-view__days__day {
      height: 31.5px !important;
    }

    .react-calendar__navigation button:enabled:hover {
      border-radius: 15px;
    }

    .react-calendar__navigation button:disabled {
      background-color: transparent;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      border-radius: 15px;
    }

    .custom-calendar {
      display: inline-grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 8px;
      width: 240px;
      height: 250px;
    }

    .react-calendar__tile {
      height: 37.5px;
    }
  }

  .slider-container {
    width: 1190px;

    @media (max-width: 100%) {
      margin-right: -52px;
    }

    @media (max-width: 100%) {
      margin-right: -22px;
    }

    @media (max-width: 100%) {
      width: 850px;
    }

    @media (max-width: 100%) {
      width: 700px;
    }

    @media (max-width: 100%) {
      width: 500px;
    }

    @media (max-width: 100%) {
      width: 380px;
    }

    @media (max-width: 969px) {
      width: 428px;
    }

    @media (max-width: 850px) {
      width: 428px;
    }

    @media (max-width: 600px) {
      width: 350px;
    }

    @media (max-width: 599px) {
      width: 428px;
    }

    @media (max-width: 550px) {
      width: 428px;
    }

    @media (max-width: 435px) {
      width: 298px;
    }

    @media (max-width: 395px) {
      width: 298px;
    }
  }

  .bg1 {
    background: hsla(0, 0%, 100%, 0.12);
    border-radius: 20px;
    margin: 1rem;
    padding: 1.56rem;
    width: 1110px;
    height: 300.91px;
  }
}
