.modalJson-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .modalJson-container {
    width: 840px;
    padding: 1%;
    // height: 550px;
    // height: 70%;

    background-color: #00233d;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    border-radius: 10px;

    .modalJson-jsonEnviado {
      width: 80%;
      height: 40%;
      overflow-y: auto;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 5px;

      pre {
        color: #00233d;
        font-size: 10px;
      }
    }
    .modalJson-separador {
      width: 100%;
      height: 7%;
      display: flex;
      align-items: end;
      justify-content: space-between;
      button {
        margin-right: 3%;
        color: #1871ca;
        border: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 1.2rem;
        cursor: pointer;
        width: 40px;
      }
      h4 {
        margin-left: 10%;
      }
    }
    .modalJson-respostaJson {
      padding: 5px;
      width: 80%;
      height: 40%;
      overflow-y: auto;
      border-radius: 5px;
      background-color: #ffffff;

      pre {
        color: #00233d;
        font-size: 10px;
      }
    }
  }
}
