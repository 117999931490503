.modalConfirmacao-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .modalConfirmacao-content {
    width: 30vw;
    height: 20vh;
    background: #1f3e55;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .modalConfirmacao-content-buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .confirm-button {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      border: 0;
      color: white;
      font-weight: bold;
      background-color: #ef8521;
      &:hover {
        transition: 0.5s;
        opacity: 0.8;
      }
    }
    .cancel-button {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      border: 0;
      color: white;
      font-weight: bold;
      background-color: #9e9e9e;

      &:hover {
        transition: 0.5s;
        opacity: 0.8;
      }
    }
  }
}
