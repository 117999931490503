.modal-container {
  
  
  .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    min-width: 0px;
    padding: 0px;
  }

  button {
    color: #f0b40c;

    font-size: 1.09rem;
   
    

    /* ---- RESPONSIVIDADE ---- */
    @media (min-width: 1800px) {
      font-size: calc(0.90rem * var(--huge-multiplier));
    }

    @media (max-width: 830px) {
      font-size: 1.09rem;
    }
  }
}

:root{
  .MuiDialogContentText-root {
    background-color: #00233d !important;
    color: white !important;
    padding: 1.1rem !important;
    margin-bottom: 1rem !important; 
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: hsl(206, 100%, 12%) !important;
    color: white !important;
  }

  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
    margin-top: -1rem !important;
  }  
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
  background-color: azure;
}