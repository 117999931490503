*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.painel{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.container {
    width: 50%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
  }
  
  .dti-login-image{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 50px;
  }

  .img-dti{

    width: 60%;
    height: 23px;

  }
  .container-login {
    width: 25vw;
    height: 50vh;
    background: #1f3e55;
    border-radius: 10px;
   
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .wrap-login {
    width: 30vw;
    padding: 40px 40px 40px 40px;
    box-sizing: border-box;
  }
  
  .login-form-title {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .wrap-input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .input {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    height: 45px;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #00233d;
  }
  
  .focus-input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    font-size: 16px;
    color: #333333;
    line-height: 45px;
    transition: all 0.4s;
  }
  
  .input:focus + .focus-input {
    top: -20px;
    left: 0;
    font-size: 12px;
    color: #333333;
    transition: all 0.4s;
  }

  .has-val.input + .focus-input {
    top: -20px;
    left: 0;
    font-size: 12px;
    color: #333333;
    transition: all 0.4s;
  }
  .container-login{
    height: 100%;
  }
  
  .container-login-form-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .login-form-btn {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    height: 45px;
    width: 100%;
    border: none;
    border-radius: 5px;
    background: #d16c0e;
    cursor: pointer;
  }
  
  .login-form-btn:hover {
    background: #da8639;
  }

  .esqueciSenha{
    width: 10vw;
    color: #d16c0e;
  }
  .esqueciSenha:hover{
    color: #da8639;
  }
.imgDTI{
    width: 20vw;
}

h1 {
  font-size: 24px;
}

.user-login__error{
  color: red;
}

.painel {
  font-size: 16px;
  padding: 20px;
}
.esqueciSenha{
  width: 10vw;
  color: #d16c0e;
  display:block;
}

@media (max-width: 800px) {

.container-login {
  width: 100%;
  height: 100%;
  background: #1f3e55;
}
h1 {
  font-size: 20px;
}
.painel {
  font-size: 18px;
  padding: 30px;
  width: 100%;
  height: 100%;
  
}
.wrap-login{
  width: 100%;
  height: 100%;
}
.container-login{
  height: 100%;
  width: 100%;
}
.container{
  width: 100%;
  height: 100%;
}
.esqueciSenha{
  width: 100%;

}
.imgDTI{
  width: 40vw;
}

}