section {

  flex-direction: column;
  display:flex; 
  

  .error-content {
    margin: 0 auto;
  }
}
  
.topic-title {
  padding-top: 2rem;
}

.error-container {
  opacity: 90%;
  margin-right: -55px;
  background: hsla(0, 0%, 100%, 0.12);
  padding: 3rem;
  
  width: 90vw;
  border-radius: 20px 20px 20px 20px;

  & + .error-container {
    border-radius: 0 0 20px 20px;
  }

  /* ---- RESPONSIVIDADE ---- */

  @media (min-width: 1800px) {
    padding: 5rem;
  }

  @media (max-width: 1430px) {
    max-width: 200%;
  }

  @media (max-width: 940px) {
    max-width: 200%;
  }
  @media (max-width: 830px) {
    max-width: 200%;
  }

  @media (max-width: 740px) {
    padding: 2.5rem 1.5rem;
    max-width: 200%;
  }

  @media (max-width: 478px) {
    padding: 0.1rem;
    margin-right: -49px;

    max-width: 200%;

    font-size: 85%;
  }

  @media (max-width: 380px) {
    max-width: 200%;
    padding: 0.1rem;
  }

  .th_deg table {
    display: inline-block;
   -webkit-overflow-scrolling: touch;
  
   
    
    
  }

  .IDErroHeader {

    width: 5%;
    margin-top: 50px;
    text-align: center;
   
  }
  .DHProcessadoErroHeader,
  .DHGeradoErroHeader {
    
    width: 10.5%;
    margin-top: 50px;
    text-align: center;
    
  }
  .DescricaoErroHeader {
    
    width: 20%;
    margin-top: 50px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  .DetalhesErroHeader {
   
    width: 2.5%;
    margin-top: 50px;
    text-align: center;
    
  }

  .IDErro {
    border-top: 2px solid white;
    width: 5%;
    margin-top: 50px;
    text-align: center;
    
  }
  .DHProcessadoErro,
  .DHGeradoErro {
    border-top: 2px solid white;
    width: 7.5%;
    margin-top: 50px;
    text-align: center;
    
  }
  .DescricaoErro {
    border-top: 2px solid white;
    width: 20%;
    margin-top: 50px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;  
   
  }
  .DetalhesErro {
    border-top: 2px solid white;
    width: 2.5%;
    margin-top: 50px;
    text-align: center;
    
    .botoes{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      position: relative;
      z-index: 1;

      div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        
      }
      .detalhes-flag{
        width: 45px;
        height: 25px;
        background-color: #406987;
        opacity: 80%;
        position: absolute;
        z-index: 3;
        margin-left: -40px;
        border-radius: 10px;
        p{
          font-size: 9px;
        }
      }

      .reprocessar-flag{
        width: 58px;
        height: 25px;
        background-color: #406987;
        opacity: 80%;
        position: absolute;
        z-index: 3;
        margin-left: 40px;
        border-radius: 10px;
        p{
          font-size: 9px;
        }
      }

      .ocultar-flag{
        width: 58px;
        height: 25px;
        background-color: #406987;
        opacity: 80%;
        position: absolute;
        z-index: 3;
        margin-left: 120px;
        border-radius: 10px;
        p{
          font-size: 9px;
        }
      }

      .sugestao-flag{
        width: 45px;
        height: 25px;
        background-color: #406987;
        opacity: 80%;
        position: absolute;
        z-index: 3;
        border-radius: 10px;
        margin-left: 50px;
        p{
          font-size: 9px;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
  }

 

  
  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(1rem * var(--huge-multiplier));
  }

  button {
    margin-top: 0.17rem;
   
  }

  @media (max-width: 478px) {
    font-size: 90%;
    text-align: center;
  }

  p {
    display: center;
    align-items: center;
    font-size: 14px;
    -webkit-line-clamp: 1; // Quantidade de linhas
    overflow: hidden; /* remove o estouro do elemento */
    text-overflow: ellipsis; /* adiciona “...” no final */
  }

  h2 {
    margin-bottom: 0.5rem;

    @media (max-width: 940px) {
      font-size: 1.8rem;
    }

    @media (max-width: 740px) {
      font-size: 1.2rem;
    }

    @media (max-width: 478px) {
      font-size: 98%;
    }
  }

  .title {
    display: flex;
    flex-direction: row;

    margin-bottom: 1rem;

    h3 {
      /* ---- RESPONSIVIDADE ---- */

      @media (max-width: 478px) {
        font-size: 90%;
      }
      & + h3 {
        margin-left: 10rem;

        /* ---- RESPONSIVIDADE ---- */

        @media (min-width: 1800px) {
          margin-left: 17.5rem;
        }

        @media (max-width: 830px) {
          margin-left: 2rem;
        }

        @media (max-width: 740px) {
          margin-left: 1.8rem;
        }

        @media (max-width: 478px) {
          margin-left: 1.65rem;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--borders);
    margin-bottom: 1rem;
  }
  

  .error-content {
    width: 100%;
    .columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      /* ---- RESPONSIVIDADE ---- */

      @media (max-width: 740px) {
        width: 320px;
      }

      @media (max-width: 740px) {
        width: 280px;
      }

      h3 {
        margin-bottom: 0.5rem;
      }

      .quantity {
        .accordion-summary {
          display: flex;

          .error-length {
            display: flex;

            p {
              display: flex;
              align-items: center;
            }
          }

          .text-summary {
            margin-left: 15rem;

            /* ---- RESPONSIVIDADE ---- */

            @media (max-width: 830px) {
              margin-left: 7rem;
            }

            @media (max-width: 740px) {
              margin-left: 6.8rem;
              font-size: 0.8rem;

              width: 185px;
            }

            @media (max-width: 478px) {
              margin-left: 4.5rem;
            }

            @media (min-width: 1800px) {
              min-width: 330px;
              margin-left: 25rem;
            }

            @media (max-width: 478px) {
              font-size: 85%;
              width: 100px;
            }

            p {
              .container {
                display: flex;
                flex-direction: column;
                margin: 0 auto;

                max-width: 1270px;
              }
            }
          }
        }

        .css-wx2bjt-MuiPaper-root-MuiAccordion-root:before {
          height: 0px;
          width: 0px;
        }

        .css-6n3dyr-MuiPaper-root-MuiAccordion-root:before {
          height: 0;
        }

        .MuiButtonBase-root {
          max-width: 85px;

          /* ---- RESPONSIVIDADE ---- */

          @media (min-width: 1800px) {
            max-width: 100px;
          }

          .MuiAccordionSummary-content {
            /* ---- RESPONSIVIDADE ---- */

            @media (max-width: 740px) {
              max-width: 330px;
            }

            @media (max-width: 478px) {
              max-width: 225px;
            }
          }
        }

        .MuiPaper-root {
          & + .MuiPaper-root {
            margin-top: -0.9rem;
          }
        }

      

        .css-ahj2mt-MuiTypography-root {
          width: 410px;

          display: flex;
          flex-direction: column;

          p {
            justify-content: left;
          }

          /* ---- RESPONSIVIDADE ---- */

          @media (min-width: 1800px) {
            width: 560px;
          }

          @media (max-width: 830px) {
            width: 310px;

            margin-top: 0rem;
            font-size: 0.7rem;
          }

          @media (max-width: 478px) {
            width: 270px;

            font-size: 0.6rem;
          }

          @media (max-width: 405px) {
            width: 230px;

            font-size: 0.5rem;
          }
        }

        .css-1m4ljmh-MuiTypography-root {
          width: auto;
        }

        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
          margin-left: -28rem;

          /* ---- RESPONSIVIDADE ---- */

          @media (min-width: 1800px) {
            margin-left: -56rem;
          }

          @media (max-width: 740px) {
            margin-left: -19rem;
          }

          @media (max-width: 478px) {
            margin-left: -13.5rem;
          }
        }

        .css-mtgw3j-MuiSvgIcon-root {
          /* ---- RESPONSIVIDADE ---- */

          @media (max-width: 478px) {
            margin-top: -0.02rem;
            font-size: 1rem;
          }
        }

        .accordion-content {
          margin-right: 4rem;
          margin-bottom: 0.7rem;
          border-bottom: 1px solid var(--borders);
          width: 100%;

          & + .accordion-content {
            border-bottom: none;
            margin-bottom: 0rem;
          }

          span {
            margin-left: 0.7rem;
            text-align: left;
          }
        }
      }
    }
  }
}

