*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container-recupera{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}
.wrap-recupera{
    min-width: 30vw;
    height: 45vh;
    background: #1f3e55;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.email{
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    height: 10vh;
    width: 20vw;
    margin-top: 3vh;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #00233d;
}

.recupera-form-btn{
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    height: 45px;
    min-width: 20vw;
    border: none;
    border-radius: 5px;
    background: #d16c0e;
    cursor: pointer;
    margin-bottom: 3vh;
}

.voltar{
    color: white;
    
}

.txt-recupera{
    margin-left: 4.2vw;
    font-size: 20px; 
}

@media (max-width: 800px) {
    .wrap-recupera{
        min-width: 85vw;
        height: 45vh;
        background: #1f3e55;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .recupera-form-btn{
        font-size: 16px;
        color: #fff;
        line-height: 1.2;
        height: 45px;
        min-width: 60vw;
        border: none;
        border-radius: 5px;
        background: #d16c0e;
        cursor: pointer;
        margin-bottom: 3vh;
    }

    .email{
        font-size: 16px;
        color: #fff;
        line-height: 1.2;
        height: 10vh;
        width: 60vw;
        margin-top: 3vh;
        border: none;
        border-radius: 5px;
        padding: 0 20px;
        box-sizing: border-box;
        background: #00233d;
    }
}
