.newTable-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  header {
    width: 100%;
  }
  main {
    width: 100%;
    flex: 1;
    display: flex;
    padding: 0 5%;

    .newTable-main-card {
      background: hsla(0, 0%, 100%, 0.12);
      opacity: 90%;
      border-radius: 20px;
      flex: 1;
      padding: 3rem;
      .newTable-main-card-header {
      }
      .newTable-main-card-section {
      }
    }
  }
}
