* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;


}

:root {
  --bg: hsl(206, 100%, 12%);
  --cards: linear-gradient(142deg,
      hsla(0, 0%, 100%, 0.12),
      78%,
      rgba(0, 176, 240, 0.877));
  --borders: hsla(0, 0%, 100%, 0.2);

  --blue: #0aa8dd;
  --white: hsl(0, 0%, 100%);
  --green: hsl(133, 67%, 65%);
  --light-green: hsla(133, 67%, 65%, 0.87);
  --yellow: hsl(54, 68%, 65%);
  --light-yellow: hsla(54, 68%, 65%, 0.87);
  --red: hsl(0, 68%, 65%);
  --light-red: hsla(0, 68%, 65%, 0.87);




  --linear-gradient-details: linear-gradient(210deg,
      hsl(56, 95%, 60%),
      hsla(335, 80%, 63%, 0.979));

  --huge-multiplier: 1.4285;
}

html,
body {
  position: relative;
  height: 100% !important;
  // width: 80% !important;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  background-color: var(--bg);

  color: #fff;

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(1rem * var(--huge-multiplier));
  }
}

section {
  padding-bottom: 3rem;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  margin-top: 3rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: calc((100%) / 3) !important;

  @media (min-width: 500px) {
    height: calc((100%) / 3) !important;
  }

  @media (min-width: 830px) {
    height: calc((100% - 33.3px) / 4) !important;
  }

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(18px * var(--huge-multiplier));
  }
}

a {
  text-decoration: none;
}

i {
  font-size: 1.375rem;

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(1.375rem * var(--huge-multiplier));
  }
}

p {
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(2rem * var(--huge-multiplier));
  }
}

h3 {
  font-size: 1rem;

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    font-size: calc(1rem * var(--huge-multiplier));
  }
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}
input{
  outline: none;
}
select{
  outline: none;
}