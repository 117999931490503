#root {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

.dashboard {
  margin-left: 5%;
  width: 95%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: start;

  .carousel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;

    img {
      width: 20%;
      height: 20%;
    }
    .column-empresa {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      height: 85%;
      min-width: 70%;
      gap: 7px;
      overflow-x: auto;
      border-radius: 10px;
    }
    .column-empresa::-webkit-scrollbar {
      height: 12px; /* largura do scroll */
      background-color: #165a7b;
      border-radius: 6px; /* cor de fundo do scroll */
    }

    .column-empresa::-webkit-scrollbar-thumb {
      background-color: #1f3e55; /* cor do indicador de rolagem */
      border-radius: 6px; /* borda do indicador de rolagem */
      width: 5px;
    }

    .item-carousel {
      display: flex;
      justify-content: center;
    }
  }

  /* ---- RESPONSIVIDADE ---- */

  span.swiper-pagination-bullet {
    background-color: var(--white);

    /* ---- RESPONSIVIDADE ---- */
    @media (min-width: 1800px) {
      height: 15px;
      width: 15px;
    }
  }

  span.swiper-pagination-bullet-active {
    background-color: var(--blue);
  }

  .swiper-pagination {
    padding: 10px;
    height: 2rem;
    margin-bottom: 5rem;

    /* ---- RESPONSIVIDADE ---- */
    @media (min-width: 1800px) {
      margin-bottom: 8rem;
    }

    @media (max-width: 830px) {
      margin-bottom: -1.51rem;
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}

.swiper-slide {
  /* ---- RESPONSIVIDADE ---- */

  @media (min-width: 500px) {
    height: calc((100%) / 3) !important;
  }

  @media (min-width: 830px) {
    height: calc((100% - 33.3px) / 4) !important;
  }

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.divLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}
