section {
  flex-direction: column;
  display: flex;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .error-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5%;
    flex-direction: column;
  }
}

.topic-title {
  padding-top: 2rem;
}

.error-container {
  opacity: 90%;
  margin-right: -55px;
  background: hsla(0, 0%, 100%, 0.12);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  width: 90vw;
  border-radius: 20px 20px 20px 20px;

  & + .error-container {
    border-radius: 0 0 20px 20px;
  }

  .error-container-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 10rem;
    padding-left: 40px;
    padding-right: 30px;

    .error-container-header-title {
      width: 100%;
      height: 30%;
    }

    .error-container-header-filtros {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;

      .error-container-header-filtros-periodo {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .error-container-header-filtros-periodo-inputs {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 10px;
          input {
            width: 45%;
            height: 30px;
            border-radius: 3px;
            border: none;
            padding-left: 5px;
            padding-right: 5px;
            color: #ccd7df;
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            filter: invert(0.9) brightness(70%) sepia(70%) saturate(10%)
              hue-rotate(20deg);
          }
        }
      }

      .error-container-header-filtros-inputs {
        width: 75%;
        display: flex;
        align-items: center;

        justify-content: space-between;
        padding-left: 8rem;

        input {
          width: 100%;
          margin-top: 10px;
          height: 30px;
          border-radius: 3px;
          border: none;
          padding-left: 5px;
          padding-right: 5px;
        }

        select {
          width: 100%;
          margin-top: 10px;
          height: 30px;
          border-radius: 3px;
          border: none;
        }

        .error-container-header-filtros-inputs-id-status-filial {
          height: 100%;
          display: flex;
          .error-container-header-filtros-inputs-id {
            height: 100%;
            width: 100px;
          }

          .error-container-header-filtros-inputs-status {
            height: 100%;
            width: 100px;
            margin-left: 20px;
          }
          .error-container-header-filtros-inputs-filial {
            height: 100%;
            width: 100px;
            margin-left: 20px;
          }
        }
      }
      .error-container-header-filtros-inputs-filtrar {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        button {
          width: 80%;
          height: 30px;
          border-radius: 3px;
          border: none;
          background-color: #406987;
          color: white;
          font-weight: bold;
          margin-top: 10px;
          &:hover {
            transition: 0.5s;
            opacity: 0.8;
          }
        }
      }
      .error-container-header-filtros-inputs-botoes {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  .error-container-body {
    padding-left: 40px;
    padding-right: 40px;

    table {
      width: 100%;
      border-spacing: 0;
      margin-top: 10px;
      margin-bottom: 4rem;

      thead {
        th {
          font-size: 12px;
          border-bottom: 1px solid #ccd7df;
        }
      }
      tbody {
        .dsStatus-sucesso {
          background-color: #32cd32;
          width: 20px;
          height: 20px;
          margin-left: 40%;
          border-radius: 100%;
        }
        .dsStatus-aguardando {
          background-color: #d8a511;
          width: 20px;
          height: 20px;
          margin-left: 40%;
          border-radius: 100%;
        }
        .dsStatus-erro {
          background-color: #ec0a33;
          width: 20px;
          height: 20px;
          margin-left: 40%;
          border-radius: 100%;
        }
        .dsStatus-outro {
          background-color: #5d5f61;
          width: 20px;
          height: 20px;
          margin-left: 40%;
          border-radius: 100%;
        }
        .dsStatus-noStatus {
          background-color: blue;
          width: 20px;
          height: 20px;
          margin-left: 40%;
          border-radius: 100%;
        }
        td {
          font-size: 12px;
          text-align: center;
          height: 50px;
          vertical-align: middle;
          border-bottom: 1px solid #ccd7df;
        }

        .data-gerada-td {
          width: 120px;
          font-size: 12px;
          text-align: center;
          height: 50px;
          vertical-align: middle;
          border-bottom: 1px solid #ccd7df;
        }
        .descricao-td {
          width: 500px;
          padding-left: 5px;
          text-align: left;
        }
        .error-container-body-td-acoes {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 50px;

          div {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .error-container-body-td-acoes-descricao {
            div {
              display: none;
              width: 45px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 80px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }

            &:hover {
              div {
                display: flex;
              }
            }
          }

          .error-container-body-td-acoes-json {
            div {
              display: none;
              width: 75px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 95px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }
            .img {
              filter: brightness(50);
            }

            &:hover {
              div {
                display: flex;
              }
            }
          }

          .error-container-body-td-acoes-reprocessar {
            div {
              display: none;
              width: 65px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 100px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }

            &:hover {
              div {
                display: flex;
              }
            }
          }
          .error-container-body-td-acoes-reprocessar-disabled {
            opacity: 0.5;
            div {
              display: none;
              width: 65px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 100px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }
          }
          .error-container-body-td-acoes-ocultar {
            div {
              display: none;
              width: 45px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 80px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }

            &:hover {
              div {
                display: flex;
              }
            }
          }
          .error-container-body-td-acoes-sugestao {
            div {
              display: none;
              width: 45px;
              height: 25px;
              background-color: #406987;
              opacity: 80%;
              position: absolute;
              margin-left: 80px;
              z-index: 3;
              border-radius: 10px;
              p {
                font-size: 9px;
              }
            }

            &:hover {
              div {
                display: flex;
              }
            }
          }
        }
      }
    }

    .error-container-body-pagination {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
