.sidebar {
  background: #1f3e55;

  width: 4.07rem;
  height: 100%;
  position: fixed;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 6rem 0;

  border-radius: 0 70px 70px 0;
  border: 1px solid #3838388a;
  box-shadow: 0.5px 4px 5px 0px hsla(0, 0%, 0%, 0.3);

  /* ---- RESPONSIVIDADE ---- */
  @media (min-width: 1800px) {
    width: 4.5rem;
  }

  @media (max-width: 991px) {
    width: 3.4rem;
  }

  @media (max-width: 478px) {
    width: 3rem;
    font-size: 90%;
  }
  .nav-flex-column {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 3.125rem;
      filter: brightness(130%);

      margin-bottom: 0.3rem;

      /* ---- RESPONSIVIDADE ---- */
      @media (min-width: 1800px) {
        width: 4.464rem;
      }

      @media (max-width: 991px) {
        width: 2.5rem;
      }
    }
  }

  i {
    .nav {
      .tooltip {
        position: absolute;
      }
    }
  }
  .container-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .more-itens {
    background: #1f3e55;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 2.8rem;
    width: 11rem;
    height: 10rem;
    border-radius: 5px;
    padding: 0.875rem;
    gap: 1rem;
    a {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-height: 10rem;
      overflow: auto;
    }
    span {
      //padding: 0.3rem 0.875rem;
      width: 100%;
      gap: 0.75rem;
      align-items: center;
      justify-content: left;
      display: flex;

      &:hover,
      &:focus {
        color: var(--white);
        opacity: 50%;
      }
      p {
        font-size: 0.875rem;
      }
    }
  }
  a {
    color: var(--white);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    z-index: 100;

    cursor: default;

    transition: all 200ms;

    p {
      &:hover,
      &:focus {
        color: var(--white);
        opacity: 50%;
      }
    }

    i {
      transition: all 200ms;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      /* ---- RESPONSIVIDADE ---- */
      @media (min-width: 1920px) {
        font-size: calc(1.5rem * var(--huge-multiplier));
      }

      @media (max-width: 991px) {
        font-size: 1.2rem;
      }

      @media (max-width: 478px) {
        font-size: 100%;
      }

      // &:hover {
      //   color: var(--white);
      //   opacity: 50%;
      // }
    }
  }

  .sair {
    cursor: pointer;
  }
}
